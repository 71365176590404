import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Digicert = lazy(() => import('sections/hero/Digicert.js'));
const DigicertPrecios = lazy(() => import('sections/services/DigicertPrecios.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const BoxCol2Digicert = lazy(() => import('sections/hero/BoxCol2Digicert.js'));
const Certimail = lazy(() => import('sections/about/CertimailDigi.js'));
const BannerSSL = lazy(() => import('sections/hero/BannerSSL.js'));
const BotonSSL = lazy(() => import('sections/hero/BotonSSL.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelock.js'));
const SeguridadInformatica = lazy(() => import('sections/about/SeguridadInformatica.js'));
const EspecSSL = lazy(() => import('sections/services/EspecSSL.js'));
const FaqDigicert = lazy(() => import('sections/services/FaqDigicert.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {


    return (
      <div>
        <Helmet>
          <title>Digicert: Certificados SSL Colombia. Medellín, Bogotá y Pereira</title>
          <meta name="description" content="Descubre la excelencia en seguridad con Certificados SSL Digicert. Expertos en certificados digitales en Colombia. Garantiza confianza y protección en línea." />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <Digicert />
            <DigicertPrecios />
            <EspecSSL />
            <BotonSanGeo />
            <BoxCol2Digicert />
            <Certimail />
            <BannerSSL />
            <BotonSSL />
            <SelloSitelock />
            <SeguridadInformatica />
            <FaqDigicert />
            <BotonSanGeo />
            <Llamenos />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`